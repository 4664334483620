import { useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";
import { Helmet } from "react-helmet";
import ResourceList from "../../components/resource-list/resource-list";

import "./home-page.css";
import bannerVideo from "./bannerVideo.mp4";

const shuffle = (arr) => {
  let array = arr;
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

function ContributorTile(props) {
  return (
    <Link to={"authors/" + props.author.Author.Slug} className="contributor">
      <img
        src={props.author.Thumbnail.URL}
        alt={props.author.Author.Name}
        width={props.author.Thumbnail.Width}
        height={props.author.Thumbnail.Height}
      />
      <span className="contributor-text">
        <h2>{props.author.Author.Name}</h2>
        <p>
          {props.author.Location}
          <br />
          {props.author.Caption}
        </p>
      </span>
    </Link>
  );
}

function TopicTile(props) {
  return (
    <div class="topic">
      <img src={props.icon} alt="" />
      <Link to={"topics/" + props.id} className="topic-text">
        <h2>{props.title}</h2>
        <p>{props.caption}</p>
      </Link>
    </div>
  );
}

export default function HomePage() {
  const pageData = useLoaderData();
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);

  const featuredAuthorsData = shuffle(
    pageData.FeaturedContributors.slice(0, 4)
  );
  const featuredAuthorsSection = featuredAuthorsData.map((author) => (
    <ContributorTile author={author} />
  ));

  const topics = pageData.Topics.map((topic) => (
    <TopicTile
      key={topic.ID}
      id={topic.ID}
      title={topic.Title}
      caption={topic.Caption}
      icon={topic.Icon}
    />
  ));

  return (
    <>
      <Helmet>
        <title>Wolfram Demonstrations Project</title>
      </Helmet>
      <header id="videoBanner">
        <video src={bannerVideo} loop autoPlay muted />
        <div className="fade" />
        <div className="text-frame">
          <div className="text">
            <h1>Bringing Ideas to Life</h1>
            <p>
              13,000+ Interactive Wolfram Notebooks for education, research,
              recreation and more
            </p>
            <p className="button-frame">
              {/* <a
                href="https://www.wolfram.com/broadcast/video.php?v=709"
                className="button"
              >
                View flyover video
              </a> */}
              <Dialog.Root
                open={videoDialogOpen}
                onOpenChange={setVideoDialogOpen}
              >
                <Dialog.Trigger className="DialogButton">
                  View flyover video
                </Dialog.Trigger>
                <Dialog.Portal>
                  <Dialog.Overlay className="DialogOverlay" />
                  <Dialog.Content className="DialogContent">
                    <iframe
                      src="https://player.vimeo.com/video/95780827?autoplay=true&color=f57900&muted=true"
                      frameborder="0"
                      allow="fullscreen"
                    ></iframe>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
            </p>
          </div>
        </div>
      </header>
      <main className="home main" style={{ margin: "35px 0 70px" }}>
        <section className="section">
          <div className="inner">
            <h1>Browse Topics</h1>
            <div className="topics-grid">{topics}</div>
          </div>
        </section>
        <section className="section">
          <div className="inner">
            <h1>Featured Demonstrations</h1>
            <p className="latest">
              <Link to="new">View Latest &raquo;</Link>
            </p>
            <ResourceList resources={pageData.Resources} pathPrefix="./" />
          </div>
        </section>
      </main>
    </>
  );
}
