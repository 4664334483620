import { useState, useRef, useEffect } from "react";
import { Link, Form, Outlet, ScrollRestoration } from "react-router-dom";
import {
  Desktop,
  Mobile,
  NotDesktop,
  NotMobile,
} from "../../components/responsive-components";

import "./root.css";
import Logo from "../../components/Logo";

const useOutsideClick = (callback) => {
  const ref = useRef();
  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    const handleKeyDown = (event) => {
      if (event.keyCode == 27) {
        callback();
      }
    };
    document.addEventListener("click", handleClick, true);
    document.addEventListener("keydown", handleKeyDown, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
      document.removeEventListener("keydown", handleKeyDown, true);
    };
  }, [ref]);
  return ref;
};

function SearchIcon() {
  return (
    <svg viewBox="0 0 32 32" stroke-linecap="round" width={16} height={16}>
      <title>Search</title>
      <path d="m30 30-8-8" fill="none" stroke="#fff" stroke-width="4" />
      <circle
        cx="13"
        cy="13"
        r="11"
        fill="none"
        stroke="#fff"
        stroke-width="4"
      />
    </svg>
  );
}

function DesktopNav(props) {
  return (
    <nav>
      <div className="inner">
        <Form action="search" method="get" className="search-form">
          <button type="submit" className="search-button">
            <SearchIcon />
          </button>
          <input
            type="text"
            name="query"
            placeholder="Search"
            className="search"
            required
          />
        </Form>
        <ul>
          <li>
            <Link to="topics">Topics</Link>
          </li>
          <li>
            <Link to="new">Latest</Link>
          </li>
          <li>
            <a href={props.randomPageAPI}>Random</a>
          </li>
          {/* <li>
<Link to="about">About</Link>
</li> */}
        </ul>
        <ul className="right">
          <li>
            <a href={props.createNotebookDownload}>Authoring Notebook</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

function MobileNav(props) {
  const [displayedView, setDisplayedView] = useState("closed"); // "closed", "open", or "search"

  function closeMenu() {
    setDisplayedView("closed");
  }
  const ref = useOutsideClick(closeMenu);

  const layoutView = (
    <div class="mobile-buttons">
      <span>
        <button
          type="button"
          className="search-button"
          onClick={() => {
            setDisplayedView("search");
          }}
        >
          <SearchIcon />
        </button>
      </span>
      <span>
        {" "}
        <Link to="topics" className="topics-button" onClick={closeMenu}>
          View Topics
        </Link>
      </span>
      <span>
        <button
          type="button"
          className="menu-button"
          onClick={() => {
            setDisplayedView((view) => (view == "closed" ? "open" : "closed"));
          }}
        >
          <svg
            viewBox="0 0 23 16"
            stroke-linecap="round"
            width={23}
            height={16}
          >
            <title>Menu</title>
            <path fill="#ddd" d="M0 .5h23v3H0zm0 6h23v3H0zm0 6h23v3H0z" />
          </svg>
        </button>
      </span>
    </div>
  );
  const openMenu = (
    <div className="open-menu">
      <ul>
        <li>
          <Link to="new" onClick={closeMenu}>
            Latest
          </Link>
        </li>
        <li>
          <a href={props.randomPageAPI} onClick={closeMenu}>
            Random
          </a>
        </li>
        <li>
          <a href={props.createNotebookDownload} onClick={closeMenu}>
            Authoring Notebook
          </a>
        </li>
      </ul>
    </div>
  );
  const searchView = (
    <Form action="search" method="get" className="search-form full">
      <button type="submit" className="search-button">
        <SearchIcon />
      </button>
      <input
        type="text"
        name="query"
        placeholder="Search"
        className="search"
        required
      />
      <button type="button" className="close-button" onClick={closeMenu}>
        <svg viewBox="0 0 32 32" stroke-linecap="round" width={16} height={16}>
          <title>Close</title>
          <path
            d="M26 26 6 6m20 0L6 26"
            fill="none"
            stroke="#fff"
            stroke-width="4"
          />
        </svg>
      </button>
    </Form>
  );

  return (
    <nav ref={ref}>
      <div className="inner">
        {displayedView == "search" && searchView}
        {displayedView != "search" && layoutView}
        {displayedView == "open" && openMenu}
      </div>
    </nav>
  );
}

function SiteFooter() {
  return (
    <footer className="site-footer">
      <div className="inner">
        <p className="footer-top">
          <a href="https://www.wolfram.com/language/">
            <Desktop>
              <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 0a16 16 0 1 1-.01 32.01A16 16 0 0 1 16 0Zm7.7 28.28A14.5 14.5 0 1 0 3 22.41l.06-.2c1.5-4.08 3.4-8.88 6.02-11.78 1.15-3.39 2.74-6.3 4.83-8.02.7 2.26 1.02 3.04 1.72 5.28 1.6-1.47 1.8-1.72 3.4-3.31 0-.04.53 4.91.28 8.64 2.17 2.2 8.52 6.21 8.52 6.21s-2.21 3.12-2.62 3.28c-.45.2-2.42 0-2.42 0l-4.09-.45 5 6.22Zm-19.17-3.4a14.48 14.48 0 0 0 16.6 4.68l-4.72-7.87-1.47-.04c-.04 0-.09 0-.17-.04-2.82-.37-6.59.2-6.59.2.25-2.2 1.51-3.47 1.51-3.47s-.81-.37-1.96-.66c.25-.9 2.62-1.96 2.95-2.09-.17.2-.82.94-1.06 1.52.86.12 2 .82 2 .82s-1.22 1.14-1.8 2.58c1.23-.2 2.21-.29 3.23-.2l11.79 1.18 1.1-1.4s-.37-1.14-.65-1.3c-2.74-1.4-6.84-4.75-9.54-7.45-.61-1.72-1.51-5.28-2.08-7-2.3 1.72-2.66 5-3.93 7.2C7.3 13.37 5.8 19.52 4.58 24.7l-.05.18ZM16 8.91c.16.53.4 1.97.4 1.97s1.24.78 1.85 1.39c.12-2.5.16-3.56.08-5.6L16 8.91Zm1.55 8.1c-.78-.45-1.47-.7-2.12-.61.08-.41.2-.7.32-1.06 1.4-.2 2.42.53 3.36 1.22-.74.21-.82.25-1.56.45Z" />
              </svg>
            </Desktop>
            <span>Wolfram&nbsp;Language</span>
          </a>
          <NotDesktop>&ensp;|&ensp;</NotDesktop>
          <a href="https://www.wolfram.com/mathematica/">
            <Desktop>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.26 64.41">
                <path d="M40.68 28.71C39.24 24.61 41 18 41 18s-6.12 3.56-10.59 3.55S20.22 18 20.22 18s1.52 6.58.22 10.82-6.65 9-6.65 9 6.94.62 10.34 3S30.62 50 30.62 50s2.09-6.29 6.22-9.15 10.63-3.09 10.63-3.09-5.36-4.95-6.79-9.05Zm-5.39 9.9a18.17 18.17 0 0 0-4.67 5.89 21.08 21.08 0 0 0-4.87-6 16.14 16.14 0 0 0-6.87-2.5A13.53 13.53 0 0 0 23 29.57a15.66 15.66 0 0 0 .47-7.33 12.15 12.15 0 0 0 7 1.88A15 15 0 0 0 38 22.24s-1 4.25.18 7.28A27.28 27.28 0 0 0 42.4 36a16.64 16.64 0 0 0-7.11 2.61Zm.14-8.13a14.8 14.8 0 0 0 2.74 4.22 15.52 15.52 0 0 0-4.36 1.86 11.12 11.12 0 0 0-3.18 3.58 13.5 13.5 0 0 0-3.29-3.55 11.26 11.26 0 0 0-4.28-1.86 15.28 15.28 0 0 0 2.45-4.39 12.58 12.58 0 0 0 .49-4.57 14.39 14.39 0 0 0 4.56 1 14.8 14.8 0 0 0 4.74-1 9.8 9.8 0 0 0 .13 4.71Zm25.83 11.68-8.87-10 8.87-10-13-2.84 1.3-13.17-12.21 5.36L30.63 0 23.9 11.51 11.7 6.15 13 19.42 0 22.26l8.87 10L0 42.16 13 45l-1.3 13.26 12.21-5.36 6.72 11.51 6.73-11.51 12.2 5.36L48.24 45ZM30.62 55.84s-4-10.34-8-13-14.45-3.31-14.45-3.31 8-6.35 9.59-12-1-14.43-1-14.43 7.81 5.65 13.64 5.65 14.12-5.64 14.12-5.64-2.21 12.1-1.43 14.66 10 11.73 10 11.73-12.61 1.67-14.86 3.3-7.61 13.04-7.61 13.04Z" />
              </svg>
            </Desktop>
            <span>Mathematica</span>
          </a>
          <NotDesktop>&ensp;|&ensp;</NotDesktop>
          <a href="https://www.wolfram.com/wolfram-u/">
            <Desktop>
              <svg viewBox="0 0 50 60" xmlns="http://www.w3.org/2000/svg">
                <path d="M25 0c17.25 0 24.85 7.65 24.85 7.65.1.09.15.22.15.35v34.5a.5.5 0 0 1-.05.22c-2.24 4.68-6.67 8.4-11.19 11.16a63.37 63.37 0 0 1-13.62 6.1.49.49 0 0 1-.28 0s-6.9-2-13.62-6.1C6.72 51.12 2.3 47.4.04 42.72A.5.5 0 0 1 0 42.5V8a.5.5 0 0 1 .15-.35S7.75 0 25 0Zm0 1C9.61 1 2.12 7.2 1 8.22v34.17c2.18 4.46 6.44 8 10.76 10.64A62.35 62.35 0 0 0 25 58.98c1-.3 7.2-2.26 13.24-5.95 4.32-2.65 8.58-6.18 10.76-10.64V8.22C47.88 7.2 40.39 1 25 1Zm0 1c15.96 0 23 7.12 23 7.12v32.74C42.83 52.78 25 58 25 58S7.17 52.78 2 41.86V9.12S9.04 2 25 2Zm13.99 26 5.7-6.34-8.37-1.8.85-8.45-7.85 3.41L25 7.5l-4.32 7.32-7.84-3.41.85 8.44-8.37 1.8 5.7 6.34-5.7 6.34 8.36 1.8-.85 8.45 7.84-3.41L25 48.5l4.32-7.33 7.84 3.41-.85-8.44 8.37-1.8-5.7-6.35Zm-26.42.68 3.35-1.23-2.6 3.35-3.81 1.3 3.06-3.42Zm13.32-7.22 5.21-1.85-.13 5.53-5.08 1.7v-5.38ZM9.86 33.66l3.96-1.34 4.25 1.24-3.55 1.11-4.66-1Zm18.36 6.26-2.33 4.01v-4.15l2.44-3.55-.11 3.69ZM29.5 16.5l4.25-1.85-2.34 3.12-4.05 1.43 2.14-2.7Zm5.97 18.17-3.55-1.12 4.24-1.24 3.98 1.35-4.67 1Zm-16.4-9.54-.16-5.53 5.25 1.85v5.38l-5.08-1.7Zm21.42 6.95-3.81-1.29-2.6-3.35 3.35 1.24 3.06 3.4ZM15.31 36.1l4.75-1.49.12 5L14.72 42l.6-5.89Zm14.5 3.52.12-5 4.75 1.48.6 5.9-5.47-2.38Zm-3.5-11.26 5.1-1.7 3.4 4.37-5.35 1.56-3.15-4.23Zm-4.6 7.85 2.47 3.55v4.16l-2.37-4.01-.1-3.7Zm.93-17.01-4.05-1.43-2.34-3.13 4.25 1.86 2.14 2.7Zm12.01.99-2.05 2.88.12-4.34 2.4-3.22-.47 4.68Zm6.97 2.45-3.96 4.4-4.68-1.72 2.8-3.93 5.84 1.25ZM15.35 20.2l-.47-4.68 2.4 3.21.13 4.35-2.06-2.88Zm8.33 8.17-3.14 4.23-5.36-1.56 3.4-4.37 5.1 1.7ZM25 10.68l3.01 5.1-3 3.8-3.02-3.8 3.01-5.1Zm0 27.42-3.15-4.55L25 29.32l3.15 4.23L25 38.1ZM8.37 22.64l5.84-1.25 2.8 3.93-4.67 1.73-3.97-4.4Z" />
              </svg>
            </Desktop>
            <span>Wolfram&nbsp;U</span>
          </a>
          <NotDesktop>&ensp;|&ensp;</NotDesktop>
          <a href="https://community.wolfram.com/">
            <Desktop>
              <svg viewBox="0 0 48 30" xmlns="http://www.w3.org/2000/svg">
                <path d="M30 6h9.11A2.9 2.9 0 0 1 42 8.89v6.4l5.85 5.86a.5.5 0 0 1-.35.85H42v5.11A2.9 2.9 0 0 1 39.11 30H20.89A2.9 2.9 0 0 1 18 27.11V24H8.89A2.9 2.9 0 0 1 6 21.11v-6.4L.15 8.85A.5.5 0 0 1 .5 8H6V2.89A2.9 2.9 0 0 1 8.89 0h18.22A2.9 2.9 0 0 1 30 2.89V6Zm-2.89 17A1.9 1.9 0 0 0 29 21.11V2.89A1.9 1.9 0 0 0 27.11 1H8.89A1.9 1.9 0 0 0 7 2.89V8.5a.5.5 0 0 1-.5.5H1.7l5.15 5.15c.1.09.15.22.15.35v6.61A1.9 1.9 0 0 0 8.89 23h18.22ZM30 7v14.11A2.9 2.9 0 0 1 27.11 24H19v3.11A1.9 1.9 0 0 0 20.89 29h18.22A1.9 1.9 0 0 0 41 27.11V21.5c0-.28.22-.5.5-.5h4.8l-5.15-5.15a.5.5 0 0 1-.15-.35V8.89A1.9 1.9 0 0 0 39.11 7H30Z" />
              </svg>
            </Desktop>
            <span>Community</span>
          </a>
          <NotDesktop>&ensp;|&ensp;</NotDesktop>
          <a href="https://www.wolfram.com/resources/">
            <Desktop></Desktop>
            <span>More&nbsp;&raquo;</span>
          </a>
        </p>
        <p className="footer-bottom">
          &copy;&nbsp;{new Date().getFullYear()}
          &nbsp;Wolfram&nbsp;Demonstrations&nbsp;Project
          &amp;&nbsp;Contributors&ensp;|&ensp;
          <a href="http://www.wolfram.com/legal/terms/wolfram-demonstrations-project.html">
            Terms&nbsp;of&nbsp;Use
          </a>
          &ensp;|&ensp;
          <a href="https://www.wolfram.com/legal/privacy/wolfram/">
            Privacy&nbsp;Policy
          </a>
          &ensp;|&ensp;
          <a href="https://resources.wolframcloud.com/forms/contact">
            Give&nbsp;feedback
          </a>
        </p>
      </div>
    </footer>
  );
}

export default function Root(props) {
  const MakersParagraph = (
    <p className="makers">
      From the makers of{" "}
      <a href="https://www.wolfram.com/language/">Wolfram Language</a> and{" "}
      <a href="https://www.wolfram.com/mathematica/">Mathematica</a>
    </p>
  );

  return (
    <div className="site-root">
      <header className="site-head">
        <div className="inner">
          <Desktop>
            <div className="right">
              <p>
                <Link to="all" title="All Demonstrations">
                  13,000+
                </Link>{" "}
                Open Interactive Demonstrations
              </p>
              {MakersParagraph}
            </div>
          </Desktop>
          <NotDesktop>{MakersParagraph}</NotDesktop>
          <h1>
            <Link to="">
              <span>
                <Logo />
              </span>
              <span>
                <span className="wolfram">Wolfram</span> Demonstrations Project
              </span>
            </Link>
          </h1>
        </div>
      </header>
      <NotMobile>
        <DesktopNav
          randomPageAPI={props.randomPageAPI}
          createNotebookDownload={props.createNotebookDownload}
        />
      </NotMobile>
      <Mobile>
        <MobileNav
          randomPageAPI={props.randomPageAPI}
          createNotebookDownload={props.createNotebookDownload}
        />
      </Mobile>
      <Outlet />
      <ScrollRestoration />
      <SiteFooter />
    </div>
  );
}
