export default function Logo(props) {
  const fillColor = props.fill || "#DF2600";
  const className = props.className || "";
  return (
    <svg
      className={"demonstrations-logo " + className}
      viewBox="0 0 96 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m48 0 10.5 17.75L77.75 9.5 75.5 30.25 96 34.5 82.25 50 96 65.5l-20.5 4.25 2.25 20.75-19.25-8.25L48 100 37.5 82.25 18.25 90.5l2.25-20.75L0 65.5 13.75 50 0 34.5l20.5-4.25L18.25 9.5l19.25 8.25L48 0Zm2 56.5c1.77-.55 6-4.15 6-6 0-5.35-8.5-5.5-8.5-5.5s4.25 1.86 4.25 6.5c0 3.09-1.75 5-1.75 5Zm-4-13c-1.77.55-6 4.15-6 6 0 5.35 8.5 5.5 8.5 5.5s-4.25-1.86-4.25-6.5c0-3.09 1.75-5 1.75-5ZM58 27s-7.01-4-14-4-21 5.9-21 23.5C23 65.52 42 65 42 65s-9.17-3.98-9-16c.3-20.97 25-22 25-22ZM34 52c-.6 3.39 4.53 13 11 13 7.47 0 11-13 11-13s-3.63 6-10.5 6C38.62 58 34 52 34 52Zm28-4c.6-3.39-4.53-13-11-13-7.47 0-11 13-11 13s3.63-6 10.5-6c6.88 0 11.5 6 11.5 6ZM38 73s7.01 4 14 4 21-5.9 21-23.5C73 34.48 54 35 54 35s9.17 3.98 9 16c-.3 20.97-25 22-25 22Z"
        fill={fillColor}
      />
    </svg>
  );
}
