import { useMediaQuery } from "react-responsive";

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  return isDesktop ? children : null;
};
export const NotDesktop = ({ children }) => {
  const isNotDesktop = useMediaQuery({ maxWidth: 999.9 });
  return isNotDesktop ? children : null;
};
export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 600.1, maxWidth: 999.9 });
  return isTablet ? children : null;
};
export const NotMobile = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 600.1 });
  return isNotMobile ? children : null;
};
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  return isMobile ? children : null;
};
