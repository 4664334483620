import { useState, useEffect, useRef } from "react";
import "./all-page-component.css";

function ResourcesSection(props) {
  const resources = props.resources.map((item) => (
    <props.ListItemComponent id={item.ID} title={item.Title} />
  ));
  let id = props.id.toLowerCase();
  if (id === "#") {
    id = "other";
  }
  return (
    <section key={id} id={id} className="resources">
      <h2>{props.id}</h2>
      <ul>{resources}</ul>
    </section>
  );
}

export default function AllPageComponent(props) {
  const [showFloatingLinkToTop, setShowFloatingLinkToTop] = useState(false);
  const linkToAllInFooterRef = useRef(null);

  function listenToScroll() {
    const refRect = linkToAllInFooterRef.current.getBoundingClientRect();
    setShowFloatingLinkToTop(
      window.innerHeight < refRect.y + refRect.height / 2
    );
  }
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  const headerLinks = Object.keys(props.pageData).map((item) => {
    let link = item.toLowerCase();
    if (item === "#") {
      link = "other";
    }
    return (
      <li key={link}>
        <a href={"#" + link}>{item}</a>
      </li>
    );
  });
  const sections = Object.entries(props.pageData).map((section) => (
    <ResourcesSection
      id={section[0]}
      resources={section[1]}
      ListItemComponent={props.ListItemComponent}
    />
  ));

  const LinkToTopIcon = (
    <svg
      viewBox="0 0 25 25"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="back-to-top-icon"
    >
      <rect className="bg" width="25" height="25" rx="3" />
      <path
        d="m8 11 4.5-4 4.5 4m-9 7 4.5-4 4.5 4"
        fill="none"
        stroke="#fff"
        stroke-width="2.5"
      />
    </svg>
  );
  const LinkToTopFloating = (
    <p className="link-to-top">
      <a href="#top">
        {LinkToTopIcon}
        <span className="visuallyhidden">Back to </span>Top
      </a>
    </p>
  );
  const LinkToTopInFooter = (
    <div style={{ margin: "5rem 0 1rem" }}>
      <hr />
      <p style={{ margin: ".5em 0" }}>
        <a href="#top" ref={linkToAllInFooterRef}>
          {LinkToTopIcon}
          Back to Top
        </a>
      </p>
    </div>
  );

  return (
    <section>
      <div className="inner">
        <h1>{props.pageName}</h1>
        {showFloatingLinkToTop && LinkToTopFloating}
        <ul className="header-links">{headerLinks}</ul>
        {sections}
        {LinkToTopInFooter}
      </div>
    </section>
  );
}
