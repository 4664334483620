import { Link } from "react-router-dom";
import { Masonry } from "masonic";
import "./resource-list.css";

function ResourceTile({ data: { PathPrefix, ID, Thumbnail, Title } }) {
  return (
    <div className="resource-tile">
      <Link to={PathPrefix + ID + "/"}>
        <div className="img-frame">
          <img
            src={Thumbnail.URL}
            alt=""
            width={Thumbnail.Width}
            height={Thumbnail.Height}
            loading="lazy"
          />
        </div>
        <p>{Title}</p>
      </Link>
    </div>
  );
}
export default function ResourceList(props) {
  const resources = props.resources.map((resource) => ({
    ...resource,
    PathPrefix: props.pathPrefix,
  }));

  return (
    <div className={"resource-list " + (props.showTitles ? "show-titles" : "")}>
      <Masonry
        items={resources}
        render={ResourceTile}
        columnGutter={15}
        rowGutter={20}
      />
    </div>
  );
}
